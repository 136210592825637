/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ziel dieses Videos war es, eine emotionale Verbindung zu potenziellen Bewerbern aufzubauen und die Mitarbeiterbindung zu stärken. Ein Recruiting- bzw. Employer-Branding-Video präsentiert die Identität des Unternehmens aus der Perspektive der Mitarbeiter. Dies stärkt nicht nur das Image gegenüber potenziellen Bewerbern, sondern lässt auch aktuelle Mitarbeiter ihre Wertschätzung spüren und stolz darauf sein, Teil des Teams zu sein."), "\n", React.createElement(_components.p, null, "Das Video wurde während des jährlichen Team-Events des Unternehmens aufgenommen. Wir sorgten für eine ungezwungene und entspannte Atmosphäre, in der sich die Mitarbeiter wohl fühlten und vor der Kamera natürlich verhielten. Dieses Team-Event förderte ein  Gemeinschaftsgefühl, welches wir sowohl in den Interview-Aussagen als auch in den Aufnahmen des Miteinanders festhielten, um die familiäre Unternehmenskultur hervorzuheben."), "\n", React.createElement(_components.p, null, "Ein überzeugendes Employer-Branding-Video stellt nicht die Produkte oder Dienstleistungen in den Mittelpunkt, sondern vor allem die Menschen, die dahinter stehen. Es ist essenziell, einen authentischen Einblick in das Unternehmen zu geben und die Unternehmensphilosophie glaubwürdig und transparent zu vermitteln. Das zeight, wofür das Unternehmen steht und welche Werte es repräsentiert."), "\n", React.createElement(_components.p, null, "OBJECT (", React.createElement(_components.a, {
    href: "http://www.object.ch",
    title: "OBJECT Website"
  }, "www.object.ch"), ") ist ein renommiertes Schweizer Unternehmen, das anspruchsvolle Software-Projekte im gesamten deutschsprachigen Raum umsetzt. Zu ihren renommierten Kunden zählen European XFE und die Schweizer Gerichte."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
